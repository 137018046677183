// Define my variables, changing the color scheme
@import "algoparc-colors";

// Import bootstrap variables
// Required 
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Import the rest of the Bootstrap modules
// Additional Bootstrap .scss fiels
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
//@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
//@import "bootstrap/badge";
@import "bootstrap/jumbotron";
//@import "bootstrap/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
//@import "bootstrap/toasts";
@import "bootstrap/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
@import "bootstrap/carousel";
//@import "bootstrap/spinners";
@import "bootstrap/utilities";
@import "bootstrap/print";


//@import "algoparc-colors";
@import "algoparc-variables";
@import "algoparc";
@import "algoparc-bootstrap4"; // algoparc definitions that depend on Bootstrap
