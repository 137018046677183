/******************************************************************** 
 * Modifications that depend on Bootstrap SCSS's to be loaded first *
 ********************************************************************/

/* ------------------------------ * 
 *      Links                     *
 * ------------------------------ */ 
            
a {
    font-weight: bold;
  }
a:active {
  color: red;
}
// keep links in navigation, jumbotron, and bibliography items normal
.navbar, .publication-item, .jumbotron { 
  a {
    font-weight: normal;
  }
}
a:hover { // remove link underlining on hover by default
  text-decoration: none;
}
p a:hover { // change link underlining to dotted line on hover only in paragraphs
  border-bottom: 1px $link-hover-color dotted;
}

/* ------------------------------ * 
 *      Footer/Contact            *
 * ------------------------------ */ 
  /* Make text in in the footer not as pronounced */
  footer  {
    h1, h2, h3, h4, h5, h6  {
      color: darken($text-muted, 10%);
      a {
        color: darken($text-muted, 10%);
      }
    }
    p, a { 
      color: $text-muted;
    }
  }
  footer a:hover {
    color:  darken($text-muted, 15%);
  }
  /* Adjust the margin of main due to the fixed footer getting taller on smaller screens */
  @include media-breakpoint-down(md) {
    main {
      margin-bottom: 12.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    main {
      margin-bottom: 16rem;
    }
  }
  @include media-breakpoint-down(xs) {
    main {
      margin-bottom: 18rem;
    }
  }


/* ------------------------------ * 
 *      Bibliography Lists        *
 * ------------------------------ */ 
  .publication-item {
    @extend .card;
     a {
       color: $gray-900;
     }
     a:hover {
       color: $text-muted;
     }
     &-header {
       @extend .card-header;
     }
     &-body {
       @extend .card-body;
     }
   }
  .publication-collection{
    @extend .card;
  }
  .publication-collection-title {
    @extend .card-header;
    background-color: $card-cap-bg;
  }
  .publication-list {
    @extend .list-group;
    @extend .list-unstyled;
  }
  /* Alternate dark and light background in bibliography items */
  .publication-list li:nth-of-type(odd) .publication-item > .publication-item-header, .publication-item:nth-of-type(odd) > .publication-item-header {
    background-color: $publication-item-odd-bg;
  }
  .publication-list li:nth-of-type(even) .publication-item > .publication-item-header, .publication-item:nth-of-type(even) > .publication-item-header {
    background-color: $publication-item-even-bg;
  }


/* ----------------------------------------------- * 
 *   Responsive Algoparc Logo in the navbar        *
 * ----------------------------------------------- */ 

  /* parameters for xs-sized screens (increased for others)  */
  // Brand logo                                    

  // navigation links
  .nav-item { 
    font-size: .95rem;
  }

  // packaged navbar
  .algoparc-navbar {
    @extend .navbar;
    @extend .navbar-expand;
    @extend .position-relative;
    @extend .flex-column;
    @extend .flex-md-row;
    // line-height: 1rem;
  }
  
  // algoparc brand logo specifications
  .algoparc-navbar-brand {
    @extend .navbar-brand;
  }
  .algoparc-brand-img {
    // @extend .algoparc-brand-img-light;
    @extend .rounded;
    @extend .border;
    @extend .d-inline-block;
    // @extend .shadow-sm;
    width: 35px;
    height: 35px;
    opacity: .8;
    @include hover-focus() {
      opacity: 1;
    }
  }
  .algoparc-navbar-nav {
    @extend .navbar-nav;
    @extend .flex-row; 
  }
  .algoparc-nav-item {
    @extend .nav-item;
    @extend .mr-n1;
    @extend .ml-n2;
    @extend .mx-sm-0;
  }
  .algoparc-nav-link {
    @extend .nav-link;
  }

  // Color scheme modifications
  $navbar-light-brand-bg-color : $gray-600;
  $navbar-dark-brand-bg-color  : $algoparc-darkblue;

    // Helper utilities
     
    // Light navbar specifications
    .algoparc-navbar-light {
      @extend .navbar-light;
      @extend .bg-light;
    }
    .algoparc-navbar-brand-img-light {
        background-color: $navbar-light-brand-bg-color;
    }
     
    // Dark navbar specifications
    .algoparc-navbar-dark  {
      @extend .navbar-dark;
      @extend .bg-algoparc-darkblue;
    }
    .algoparc-navbar-brand-img-dark {
        background-color: $navbar-dark-brand-bg-color;
    }

  // Select the color scheme here 
  .algoparc-navbar {
    // @extend .algoparc-navbar-light;
    @extend .algoparc-navbar-dark;
  }
  .algoparc-brand-img {
    // @extend .algoparc-navbar-brand-img-light;
    @extend .algoparc-navbar-brand-img-dark;
  }
  .jumbotron {
    background-color: $algoparc-jumbotron-bg-color;
  }

  // On sm-sized screens or larger:
  // - incrase nav-item font size to 1rem
  @include media-breakpoint-up(sm) {
    .nav-item {
      font-size: 1rem;
    }
  }
  // On md-sized screens or larger:
  // - incrase nav-item font size to 1.1rem
  // - increase the brand logo
  @include media-breakpoint-up(md) { 
    .nav-item {
      font-size: 1.1rem;
    }
    .algoparc-brand-img {
      width: 50px;
      height: 50px;
    }
    // @supports (position: sticky) { 
    //   .navbar { // Values are the same as .sticky-top in bootstrap/utilities/_position.scss
    //     position: sticky !important;
    //     top: 0;
    //     z-index: $zindex-sticky;
    //   }
    // }
  }

/* ----------------------------------------------- * 
 *   Embedding Google Maps                         *
 * ----------------------------------------------- */ 
.algoparc-map {
  @extend .col-xl-6;
  @extend .col-lg-7;
  @extend .col-md-9;
  @extend .embed-responsive;
  @extend .embed-responsive-1by1;
}


/* ----------------------------------------------- * 
 *   Carousel                                      *
 * ----------------------------------------------- */ 
 
 /* normalize the height of pictures within the carousel */
.carousel {
 height: 300px;
}
.carousel-inner {
  height: 100%;
   
}

.carousel-item {
  background-size: cover;
  background-position: center;
  height: 100%;

  img {
   visibility: hidden;
  }
}


