/* General AlgoPARC macros*/

/*
.center-aligned {
  text-align: center;
}

*/

/* Settings for sticky footer at the bottom */
html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}
body {
  /* Margin bottom by footer height + 20px */
  margin-bottom: 175px;
}
main {
  padding-bottom: 20px; /* padding between main content and footer */

}
footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  //height: 175px; /* make sure to set body's margin-bottom to this value too */
  /*line-height: 180px; /* Vertically center the text there */
  border-top: 1px solid black;
}

/* Adjust footer height with window width (due to text moving) */
/*
@media (min-width: 992px) {
  footer {
    height: 175px;
  }
  body {
    margin-bottom: 175px;
  }
}
@media (max-width: 991px) {
  footer {
    height: 200px;
  }
  body {
    margin-bottom: 200px;
  }
}
@media (max-width: 532px) {
  footer {
    height: 225px;
  }
  body {
    margin-bottom: 220px;
  }
}

@media (max-width: 496px) {
  footer {
    height: 250px;
  }
  body {
    margin-bottom: 250px;
  }
}
@media (max-width: 487px) {
  footer {
    height: 275px;
  }
  body {
    margin-bottom: 275px;
  }
}
@media (max-width: 
@media (max-width: 451px) {
  footer {
    height: 300px;
  }
  body {
    margin-bottom: 300px;
  }
}

*/

/* Align contact info within the footer */
#contact, address {
  margin-top: 20px;
}


/*
// A fix for sticky navbar when linking to the #id. But messes up spacing for section > h1 at the top of the page
section {
  padding-top: 81px;
  margin-top: -56px;
}
*/

.algoparc-box {
  width: 500px;
  height: 500px;
  background-color: $algoparc-darkblue;
}

/*
.list-group-item:nth-of-type(even) {
  background: #f6f6f6;
}
*/



// .algoparc-logo {
//   margin-left: 30px;
//   margin-right: 30px;
//   /*width: 450px; */
//   /* text-align: right;*/
// }
// 
// /* Make Algoparc logo scale down automatically once window is smaller than 550px */
// @media (max-width: 550px) {
//   .algoparc-logo  {
//       width: 80%;
//       height: auto;
//   }
// }


/* flash class makes font red */

.flash {
  color: red;
  font-weight: bold;
  font-style: italic;
}

/* Section headings */
section > h1, h1.section/*,
section > h2, h2.section,
section > h3, h3.section,
section > h4, h4.section,
section > h5, h5.section,
section > h6, h6.section */ { 
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}


/* ------------------------------------------ *
 * Site Navigation                            *
 * ------------------------------------------ */



/* ------------------------------------------ *
 * Algoparc logo (outside of navbar)          *
 * ------------------------------------------ */
.algoparc-logo-img {
  height : 80px;
  background-color: white;
}

/* ------------------------------------------ *
 * Algoparc logo (inside the navbar)          *
 * ------------------------------------------ */
/*
.algoparc-brand-background {
  //background-color: $algoparc-logo-bg;
  //padding: 1px 5px 1px 5px;
  //margin: -5px;
  display: none;
}
.algoparc-brand-img {
  height : 30px;
  width: 30px;
  background-color: $algoparc-logo-bg;
  vertical-align: middle !important;
  //margin: 5px 1px 5px 1px;
  display: none;
}
.algoparc-brand-text {
  // font-size: 1.6rem;
  font-size: 28px;
  font-weight: 1000; 
  background-color: $algoparc-logo-bg;
  vertical-align: middle !important;
  display: none;
  //margin-left: -5px; 
}
.algoparc-brand-home { // home icon for xs-sized screens
  background-color: $primary;
  height : 25px;
  margin: 0;
  display: inline-block;
}
*/
