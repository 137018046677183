/************************************************
 *  Variables that should be set before 
 *  loading bootstrap SCSS files
 ************************************************/
// Make display-1, ..., display-4 fonts more reasonable size  (should move to a separate file)
$display1-size:               4rem;
$display2-size:               3.5rem;
$display3-size:               2.5rem;
$display4-size:               1.5rem;

// Define Algoparc colors
$algoparc-darkblue : #112899; 
$algoparc-blue : #3158b9; 
$algoparc-light-blue : #d7e3f4; 
$algoparc-jumbotron-bg-color : #f2f6ff;
$algoparc-logo-bg : #f8f9fa; //#f8ffff; 
$link-hover-decoration: none;

// color theme modifications
$primary: $algoparc-darkblue;  // Primary color (navbar, primary buttons, etc)



.bg-grey {
  background-color: #f6f6f6;
}

.bg-light-grey {
  background-color: #fbfbfb;
}
body {
  background-color: blue;
  // background:url(../img/textura.gif) repeat;
}

/* Blue color in the AlgoPARC logo for background*/
.bg-algoparc-blue {
  background-color: $algoparc-blue;
}

/* Blue color in the AlgoPARC logo for text*/
.algoparc-blue {
  color: $algoparc-blue;
}

/* color for the banner, navbar, etc */
.bg-algoparc-darkblue {
  background-color: $algoparc-darkblue; 
}

.algoparc-darkblue {
  color: $algoparc-darkblue !important; 
}

/* Text colors of all links in the main text (excluding navbar)  */
/*
a {
  color: #112800;
}
a:hover {
  color: #112899;
}
*/

/*
a:hover {
  border-bottom: 1px gray dotted;
}
*/
/*
p a:hover, #contact a:hover {
  text-decoration: none;
  border-bottom: 1px gray dotted;
}
*/


/*
 * Main navigation
 *
 * Turn the `.navbar` at the top of the docs blue.
 */

/*
.nav {
  text-shadow: 0 -1px 0 rgba(0,0,0,.15);
  background-color: #3158b9;
  border-color: #3158b9;
  box-shadow: 0 1px 0 rgba(255,255,255,.1);
}
.nav .navbar-collapse {
  border-color: #3158b9;
  color: #fff;
}
.nav .navbar-brand {
  color: #fff;
}
.nav .navbar-nav > li > a {
  color: #cdbfe3;
}
.nav .navbar-nav > li > a:hover {
  color: #fff;
  background-color: #4168c9;
}
.nav .navbar-nav > .active > a,
.nav .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #2148a9;
}
.nav .navbar-toggle {
  background-color: #4168c9;
  border-color: #3158b9;
}
.nav .navbar-toggle:hover {
  background-color: #4168c9;
  border-color: #3158b9;
}

*/



/* Main navbar */
.navbar-algoparc-colors {
  background-color: #112899; /*#222; */  
  border-color: #2148a9; /*080808;  */
}

/* Color of dropdowns when open */
.navbar-algoparc-colors .navbar-nav > .open > a,
.navbar-algoparc-colors .navbar-nav > .open > a:hover,
.navbar-algoparc-colors .navbar-nav > .open > a:focus {
  background-color: #2148a9; /*#080808;  */
}

/* Color of active navbar links */
.navbar-algoparc-colors .navbar-nav > .active > a,
.navbar-algoparc-colors .navbar-nav > .active > a:hover,
.navbar-algoparc-colors .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #5178d9; /*#080808; */  
}


/* Menu button colors on small screens */
.navbar-dark .navbar-toggle {
  border-color: #5178d9; /*#333; */
}
.navbar-dark .navbar-toggle:hover,
.navbar-dark .navbar-toggle:focus {
  background-color:  #5178d9;  /*#333; */
}


/* Text colors on the navbar */
.navbar-algoparc-colors .navbar-nav > li > a {
  color: #ccc;
}

@media (max-width: 767px) {
/* new */
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu {
    background-color: #fff; /* #5178d9; */
  }
/* */
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu .divider {
    background-color: #aaa; /*#080808;*/
  }
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > li > a {
    color: #000; /*#9d9d9d; */
  }
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #000;
    background-color: #f0f0f0; 
  }
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #000; 
    background-color: #080808;
  }
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-algoparc-colors .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
}

/* Other navbar colors from bootstrap.css */
/*
.navbar-algoparc-colors .navbar-brand {
  color: #9d9d9d; 
}
.navbar-algoparc-colors .navbar-brand:hover,
.navbar-algoparc-colors .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-algoparc-colors .navbar-text {
  color: #9d9d9d; 
}
.navbar-algoparc-colors  > li > a {
  color: #9d9d9d; 
}
.navbar-algoparc-colors .navbar-nav > li > a:hover,
.navbar-algoparc-colors .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-algoparc-colors .navbar-nav > .disabled > a,
.navbar-algoparc-colors .navbar-nav > .disabled > a:hover,
.navbar-algoparc-colors .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}
.navbar-algoparc-colors .navbar-toggle {
  border-color: #333;
}
.navbar-algoparc-colors .navbar-toggle:hover,
.navbar-algoparc-colors .navbar-toggle:focus {
  background-color: #333;
}
.navbar-algoparc-colors .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-algoparc-colors .navbar-collapse,
.navbar-algoparc-colors .navbar-form {
  border-color: #101010;
}
.navbar-algoparc-colors .navbar-link {
  color: #9d9d9d;
}
.navbar-algoparc-colors .navbar-link:hover {
  color: #fff;
}
.navbar-algoparc-colors .btn-link {
  color: #9d9d9d;
}
.navbar-algoparc-colors .btn-link:hover,
.navbar-algoparc-colors .btn-link:focus {
  color: #fff;
}
.navbar-algoparc-colors .btn-link[disabled]:hover,
fieldset[disabled] .navbar-algoparc-colors .btn-link:hover,
.navbar-algoparc-colors .btn-link[disabled]:focus,
fieldset[disabled] .navbar-algoparc-colors .btn-link:focus {
  color: #444;
}
*/


